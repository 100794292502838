<template>
  <div></div>
</template>

<script>
import QC from 'QC'

export default {
  name: "QQlogin.vue",
  mounted() {
    var _this = this
    if (QC.Login.check()) {
      QC.Login.getMe(function (openId, accessToken) {
        localStorage.setItem('token', accessToken)
        QC.api('get_user_info').success(function a(res) {
          _this.$store.commit('setUserInfo', {
            nickname: res.data.nickname,
            figureurl_qq_1: res.data.figureurl_qq_1
          })
        })
        if (localStorage.getItem('urlBeforeLogin')) {
          window.location = localStorage.getItem('urlBeforeLogin')
        } else {
          _this.$router.push("/")
        }
      })
    } else {
      console.log('qc check false')
    }
  }
}
</script>

<style scoped>

</style>